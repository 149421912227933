/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        /*
        $(window).on('load', function() {
          // makes sure the site is loaded first
          $('#preloader').delay(1000).fadeOut(400, function() {
            $('body').css({
              'overflow': 'visible'
            });
            //start slider on init
            if ($('#load-results').length > 0) {
            
            }
           
          });
        });
        */
        setTimeout(function() {
          $('#ctn-preloader').addClass('loaded');
          // Una vez haya terminado el preloader aparezca el scroll
          $('body').removeClass('no-scroll-y');
          if ($('#ctn-preloader').hasClass('loaded')) {
            // Es para que una vez que se haya ido el preloader se elimine toda la seccion preloader
            $('#preloader').delay(800).queue(function() {
             $(this).remove();
            });
          }
        }, 2500);
        if ($('#load-results').length > 0) {
          setGallerySlider();
          setHomeOffers();
          setQuicklinks();
        }

        function setGallerySlider() {
          $('.ajax-container').css({
            display: 'inline-block'
          }).animate({
            "opacity": 1,
            'min-height': "450px"
          }, 'fast');
          var json = null;
          $.ajax({
            url: "https://budget.frhdesigns.com/wp-json/wp/v2/home-api?filter[orderby]=desc&per_page=10",
            cache: true,
            type: "GET",
            async: true,
            dataType: "json",
            beforeSend: function() {
              $('.home-gallery').append('<div class="gallery-loader"><div class="loading_scene">' + '<div class="loader_img"></div>' + '</div></div>');
            },
            success: function(data) {
              json = data;
              //console.log(json);
              var sliderContainer = document.getElementById("load-results"),
                sliderImage = '<div class="slider slider-for onstart_slider">';
              for (var i = 0; i < json.length; i++) {
                var item = json[i];
                //console.log(item);
                var title = item.title.rendered;
                //var caption = item.meta_box.gallery_text_field;
                //var cap_url = item.meta_box._cap_url;
                var _url = item.acf.productbrand_url;
                var img_obj = item.acf.featured_image;
                var brand_img = item.acf.brand_logo;
                var tag_line = item.acf.tag_line;
                //var tmp_img = "{{ 'white.gif' | asset_url }}";
                sliderImage += '<div><figure><a href="' + _url + '" class="item">' + '<img class="img-fluid" src="' + img_obj + '" alt="' + title + '" /></a>';
                if (tag_line) {
                  sliderImage += '<div class="desc-txt">';
                  sliderImage += '<div class="v_align">';
                  sliderImage += '<h3>' + tag_line + '</h3>';
                  if (brand_img) {
                    sliderImage += '<figure class="logo-img">';
                    sliderImage += '<img src="' + brand_img + '" class="img-fluid" alt="' + title + '">';
                    sliderImage += '</figure>';
                  }
                  sliderImage += '<div class="cta">';
                  sliderImage += '<a class="arrow_link" href="' + _url + '" tabindex="0">Read More<i class="ion-ios-arrow-thin-right"></i></a>';
                  sliderImage += '</div>';
                  sliderImage += '</div>';
                  sliderImage += '</div>';
                }
                sliderImage += '</figure>';
                sliderImage += '</div>';
              }
              //e/o for loop
              sliderImage += '</div>';
              setTimeout(function() {
                sliderContainer.innerHTML = sliderImage;
                var showArrows = !('ontouchstart' in document.documentElement);
                if ('ontouchstart' in document.documentElement) {
                  //this is mobile
                  var showFade = !('ontouchstart' in document.documentElement);
                } else {
                  var showFade = 'fadeOut';
                }
                $('.slider-for').slick({
                  infinite: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  autoplay: true,
                  autoplaySpeed: 4000,
                  arrows: true,
                  responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                        arrows: true,
                      }
                  },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        //centerMode: true,
                        arrows: false,
                      }
                  },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false,
                      }
                        }
                        // You can unslick at a given breakpoint now by adding:
                        // settings: "unslick"
                        // instead of a settings object
                      ]
                });
              }, 0);
              //e/o setTime
            },
            complete: function() {
              //remove loading gif
              $('.gallery-loader').remove();
              //$('.spinner-holder').remove();
              //$('#signup-modal').modal('show')
            }
          });
          //end ajax
        }
        //end 
        function setHomeOffers() {
          $('.load-promos').css({
            display: 'block'
          }).animate({
            "opacity": 1,
            'min-height': "290px"
          }, 'fast');
          var json = null;
          $.ajax({
            url: "https://budget.frhdesigns.com/wp-json/wp/v2/promo-api?filter[orderby]=desc&per_page=6",
            cache: true,
            type: "GET",
            async: true,
            dataType: "json",
            beforeSend: function() {
              $('.load-promos').append('<div class="gallery-loader"><div class="loading_scene">' + '<div class="loader_img"></div>' + '</div></div>');
            },
            success: function(data) {
              json = data;
              //console.log(json);
              var promoContainer = document.getElementById("load-promo"),
                promoItem = '<div class="promo-entry row">';
              for (var i = 0; i < json.length; i++) {
                var item = json[i];
                //console.log(item);
                var countClass = "col-md-6 col-lg-6";
                if (json.length == 3) {
                  countClass = "col-md-12 col-lg-4";;
                }
                var promo_title = item.title.rendered;
                var promo_url = item.acf.promo_flyer;
                var promo_discount = item.acf.promo_discount;
                var promo_desc = item.acf.offer_description;
                //var tmp_img = "{{ 'white.gif' | asset_url }}";
                promoItem += '<div class="col-12 col-sm-12 ' + countClass + '"><div class="card text-center">';
                promoItem += ' <h2><strong>' + promo_discount + '</strong><span>' + promo_title + '</span></h2>';
                promoItem += '<a href="#" class="overlay_view" data-img="' + promo_url + '"><span class="more">View Promotion</span></a>';
                promoItem += '<div class="info"><small>' + promo_desc + '</small>';
                promoItem += '</div>';
                promoItem += '</div>';
                promoItem += '</div>';
              }
              //e/o for loop
              promoItem += '</div>';
              setTimeout(function() {
                promoContainer.innerHTML = promoItem;
              }, 0);
              //e/o setTime
            },
            complete: function() {
              //remove loading gif
              $('.gallery-loader').remove();
              //modal view
              function modalFunc() {
                $('.overlay_view').click(function(event) {
                  event.preventDefault();
                  //console.log(termValue);
                  $('#view_modal').modal();
                  //grab the data id
                  var img_url = $(this).data('img');
                  $('#view_modal .modal-content').css({
                    display: 'inline-block'
                  }).animate({
                    "opacity": 1,
                    'min-height': "350px"
                  }, 'slow').html('<div class="rectangle-bounce"><img class="loader" src="http://samherbert.net/svg-loaders/svg-loaders/circles.svg" /></div>');
                  var htmlData = '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
                  htmlData += '<div class="modal-body"><div><img src="' + img_url + '" class="img-fluid" alt="promotional offer" /></div></div>';
                  $('#view_modal .modal-content').html(htmlData);
                  $('#view_modal').on('hidden.bs.modal', function() {
                    $(".modal-content").empty();
                  });
                });
                //
                //<iframe src="' + pdf_url + '" style="width:100%; height:600px;" frameborder="0"></iframe>
                //end click
                $('#view_modal').on('hidden.bs.modal', function() {
                  $(".modal-content").empty();
                });
              }
              setTimeout(function() {
                modalFunc();
              }, 1000);
            }
          });
          //end ajax
        }
        //end 
        function setTradeshows() {
          $('.load-shows').css({
            display: 'block'
          }).animate({
            "opacity": 1,
            'min-height': "290px"
          }, 'fast');
          var json = null;
          $.ajax({
            url: "https://budget.frhdesigns.com/wp-json/wp/v2/tradeshow-api?filter[orderby]=desc&per_page=30",
            cache: true,
            type: "GET",
            async: true,
            dataType: "json",
            beforeSend: function() {
              $('.load-promos').append('<div class="gallery-loader"><div class="loading_scene">' + '<div class="loader_img"></div>' + '</div></div>');
            },
            success: function(data) {
              json = data;
              //console.log(json);
              var showsContainer = document.getElementById("load-shows"),
                showsItem = '<div class="shows-entry">';
              for (var i = 0; i < json.length; i++) {
                var item = json[i];
                //console.log(item);
                var show_title = item.title.rendered;
                var show_location = item.acf.location;
                var show_name = item.acf.exhibition_name;
                var show_url = item.acf.link;
                var show_start = item.acf.start_date;
                var show_end = item.acf.to_date;
                //var tmp_img = "{{ 'white.gif' | asset_url }}";
                showsItem += ' <article class="show-item">';
                showsItem += '<div class="row align-items-center">';
                showsItem += ' <div class="col col-sm-3 col-md-2 item">';
                showsItem += '  <div class="sub-col">';
                showsItem += '  <time class="dates">';
                showsItem += ' ' + show_start + '<br>';
                showsItem += '	 ' + show_end + '';
                showsItem += '	</time>';
                showsItem += '  </div>';
                showsItem += '  </div>';
                showsItem += ' <div class="col col-sm-3 col-md-4 item ">';
                showsItem += ' <div class="sub-col">';
                showsItem += '  <h3>';
                showsItem += '<a target="_blank" href="' + show_url + '">';
                showsItem += '   ' + show_name;
                showsItem += '		</a>';
                showsItem += '	</h3>';
                showsItem += '   </div>';
                showsItem += '  </div>';
                showsItem += '  <div class="col col-sm-4 col-md-4 item">';
                showsItem += '  <div class="sub-col">';
                showsItem += '   <a target="_blank" href="' + show_url + '">' + show_location + '</a>';
                showsItem += '   </div>';
                showsItem += '  </div>';
                showsItem += '  <div class="col col-sm-2 col-md-2 item">';
                showsItem += ' <div class="sub-col">';
                showsItem += '   <div class="external_link text-right">';
                showsItem += '     <a target="_blank" href="' + show_url + '"><svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0,1)"><rect id="bg" fill="#ddd" x="0" y="2" width="9" height="9" rx="1.5"></rect><path d="M9.18198052,1 L6.5,1 L6.5,0 L11,0 L11,1 L11,4.5 L10,4.5 L10,1.59619408 L4.02512627,7.57106781 L3.31801948,6.86396103 L9.18198052,1 Z" id="arrow" fill="#0483c6"></path></g></svg></a>';
                showsItem += '    </div>';
                showsItem += '    </div>';
                showsItem += '  </div>';
                showsItem += ' </div>';
                showsItem += '</article>';
              }
              //e/o for loop
              showsItem += '</div>';
              setTimeout(function() {
                showsContainer.innerHTML = showsItem;
              }, 0);
              //e/o setTime
            },
            complete: function() {
              //remove loading gif
              $('.gallery-loader').remove();
            }
          });
          //end ajax
        }
        //end 
        if ($('.load-shows').length > 0) {
          setTradeshows();
        }

        function setQuicklinks() {
          $('.load-quicklinks').css({
            display: 'block'
          }).animate({
            "opacity": 1,
            'min-height': "290px"
          }, 'fast');
          var json = null;
          $.ajax({
            url: "https://budget.frhdesigns.com/wp-json/wp/v2/quicklinks-api?filter[orderby]=desc&per_page=3",
            cache: true,
            type: "GET",
            async: true,
            dataType: "json",
            beforeSend: function() {
              $('.load-quicklinks').append('<div class="gallery-loader"><div class="loading_scene">' + '<div class="loader_img"></div>' + '</div></div>');
            },
            success: function(data) {
              json = data;
              //console.log(json);
              var linkContainer = document.getElementById("load-quicklinks"),
                linkItem = '<div class="row">';
              for (var i = 0; i < json.length; i++) {
                var item = json[i];
                var link_title = item.acf.quick_title;
                var link_url = item.acf.quick_link_url;
                var link_img = item.acf.quick_link_image;
                //var tmp_img = "{{ 'white.gif' | asset_url }}";
                linkItem += '<div class="col-12 col-sm-12 col-md-4"> <a href="' + link_url + '" class="thumbnail">';
                linkItem += '<div class="img-holder">';
                linkItem += '<figure class="frame_img" style="background-image: url(' + link_img + ')"></figure>';
                linkItem += '</div>';
                linkItem += '<h3 class="frame-btn_title">' + link_title + '</h3>';
                linkItem += '</a>';
                linkItem += '</div>';
              }
              //e/o for loop
              linkItem += '</div>';
              setTimeout(function() {
                linkContainer.innerHTML = linkItem;
              }, 0);
              //e/o setTime
            },
            complete: function() {
              //remove loading gif
              $('.gallery-loader').remove();
            }
          });
          //end ajax
        }
        //end   
        var stickyOffset = $('.menu-wrapper').offset().top;
        $(window).scroll(function() {
          var sticky = $('body'),
            scroll = $(window).scrollTop();
          if (scroll >= stickyOffset) sticky.addClass('fixed');
          else sticky.removeClass('fixed');
        });
        // $("#sticky_item").stick_in_parent();
        var window_width = $(window).width();
        if (window_width < 768) {
          $("#sidebar").trigger("sticky_kit:detach");
        } else {
          make_sticky();
        }
        $(window).resize(function() {
          window_width = $(window).width();
          if (window_width < 768) {
            $("#sidebar").trigger("sticky_kit:detach");
          } else {
            make_sticky();
          }
        });

        function make_sticky() {
          $("#sidebar").stick_in_parent({
            //parent: '.entry-content',
            offset_top: 120
          });
        }
        $('#hdrCart').on('click', function() {
          //$('.hdrMenu').toggleClass('open');
          $('body').toggleClass('noscroll');
          $('.overlayMenu').toggleClass('darken');
        });
        $('.overlayMenu').on('click', function() {
          $('.hdrMenu').removeClass('open');
          $('body').removeClass('noscroll');
          $(this).removeClass('darken');
        });
        //addClass()
        setInterval(function() {
          $('.shipping-banner-container').each(function() {
            var $cur = $(this).find('.current').removeClass('current');
            var $next = $cur.next().length ? $cur.next() : $(this).children().eq(0);
            $next.addClass('current');
          });
        }, 3000);
        //shopping cart is open
        if ($(".hdrMenu").hasClass("open")) {}
        //mobile menu
        var isLateralNavAnimating = false;
        //open/close lateral navigation
        $('.cd-nav-trigger').on('click', function(event) {
          event.preventDefault();
          //stop if nav animation is running
          if (!isLateralNavAnimating) {
            if ($(this).parents('.csstransitions').length > 0) isLateralNavAnimating = true;
            $('.cd-side-nav').toggleClass('nav-is-visible');
            $('body').toggleClass('overflow_hidden');
            $('.cd-nav-trigger').toggleClass('nav-is-visible');
            $('.cd-side-nav').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
              //animation is over
              isLateralNavAnimating = false;
            });
          }
        });
        //click on item and show submenu
        $('.has-children > a').on('click', function(event) {
          var selectedItem = $(this);
          event.preventDefault();
          if (selectedItem.parent('li').hasClass('selected')) {
            selectedItem.parent('li').removeClass('selected');
          } else {
            $('.cd-side-nav').find('.has-children.selected').removeClass('selected');
            selectedItem.parent('li').addClass('selected');
          }
        });
        //open/close lateral navigation
        $('.toggle-search').on('click', function() {
          $(this).toggleClass("active");
          $('body').toggleClass("search_open");
          $('.search_store').trigger('focus');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.